<template>
  <div class="manage-index-body">
    <div class="manage-info">
      <div class="manage-info-left">
        <div class="info-user">
          <div class="user-cover">
            <img :src="detailData.TeachingStudioinfo.photo_url">
          </div>
          <div class="user-right">
            <p class="user-studioName ellipsis-2">{{ detailData.TeachingStudioinfo.teaching_studio_name }}</p>
            <div class="user-info">
              <!-- <p class="user-name ellipsis-1">{{ detailData.TeachingStudioinfo.realname }}</p> -->
              <p class="user-name ellipsis-1">{{ unserInfo.username }}</p>
              <p class="user-role"><span>{{ detailData.role_name }}</span></p>
            </div>
          </div>
        </div>
      </div>
      <div class="manage-info-right">
        <div class="census-block">
          <div class="census-icon">
            <img src="@/assets/images/studioHome/1031-1.png">
          </div>
          <div class="census-info">
            <p class="census-label">今日访问量：</p>
            <div class="census-data">
              <p class="data-text">{{ detailData.today || 0 }}</p>
              <i v-if="detailData.today">
                <img src="@/assets/images/studioHome/fadfa4654678.png" class="data-icon">
              </i>
            </div>
          </div>
        </div>
        <div class="census-block ml80">
          <div class="census-icon">
            <img src="@/assets/images/studioHome/1031-2.png">
          </div>
          <div class="census-info">
            <p class="census-label">总访问量：</p>
            <div class="census-data">
              <p class="data-text">{{ detailData.total_view_count || 0 }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="manage-box-1">
      <div class="manage-box-title">数据统计:</div>
      <ul class="statistics-list">
        <li class="statistics-list-item">
          <div class="item-top">
            <h3 class="item-title">工作室成员</h3>
            <p class="item-number">{{ detailData.user_count || 0 }}</p>
          </div>
          <div class="item-bottom">
            <p class="item-text">本月新增</p>
            <i class="item-icon el-icon-top"></i>
            <span class="item-number">{{ detailData.user || 0 }}</span>
          </div>
          <i class="item-bg">
            <img src="@/assets/images/studioHome/index_statistics_bg.png">
          </i>
        </li>
        <li class="statistics-list-item">
          <div class="item-top">
            <h3 class="item-title">四名讲堂</h3>
            <p class="item-number">{{ detailData.course_live_count || 0 }}</p>
          </div>
          <div class="item-bottom">
            <p class="item-text">本月新增</p>
            <i class="item-icon el-icon-top"></i>
            <span class="item-number">{{ detailData.course_live || 0 }}</span>
          </div>
          <i class="item-bg">
            <img src="@/assets/images/studioHome/index_statistics_bg.png">
          </i>
        </li>
        <li class="statistics-list-item">
          <div class="item-top">
            <h3 class="item-title">教研活动</h3>
            <p class="item-number">{{ detailData.activity_count || 0 }}</p>
          </div>
          <div class="item-bottom">
            <p class="item-text">本月新增</p>
            <i class="item-icon el-icon-top"></i>
            <span class="item-number">{{ detailData.activity || 0 }}</span>
          </div>
          <i class="item-bg">
            <img src="@/assets/images/studioHome/index_statistics_bg.png">
          </i>
        </li>
        <li class="statistics-list-item">
          <div class="item-top">
            <h3 class="item-title">研修资源</h3>
            <p class="item-number">{{ detailData.resource_count || 0 }}</p>
          </div>
          <div class="item-bottom">
            <p class="item-text">本月新增</p>
            <i class="item-icon el-icon-top"></i>
            <span class="item-number">{{ detailData.resource || 0 }}</span>
          </div>
          <i class="item-bg">
            <img src="@/assets/images/studioHome/index_statistics_bg.png">
          </i>
        </li>
        <li class="statistics-list-item">
          <div class="item-top">
            <h3 class="item-title">教师文章</h3>
            <p class="item-number">{{ detailData.article_count || 0 }}</p>
          </div>
          <div class="item-bottom">
            <p class="item-text">本月新增</p>
            <i class="item-icon el-icon-top"></i>
            <span class="item-number">{{ detailData.article || 0 }}</span>
          </div>
          <i class="item-bg">
            <img src="@/assets/images/studioHome/index_statistics_bg.png">
          </i>
        </li>
        <li class="statistics-list-item">
          <div class="item-top">
            <h3 class="item-title">活动报道</h3>
            <p class="item-number">{{ detailData.report_count || 0 }}</p>
          </div>
          <div class="item-bottom">
            <p class="item-text">本月新增</p>
            <i class="item-icon el-icon-top"></i>
            <span class="item-number">{{ detailData.report || 0 }}</span>
          </div>
          <i class="item-bg">
            <img src="@/assets/images/studioHome/index_statistics_bg.png">
          </i>
        </li>
        <li class="statistics-list-item">
          <div class="item-top">
            <h3 class="item-title">成果展示</h3>
            <p class="item-number">{{ detailData.certificate_count || 0 }}</p>
          </div>
          <div class="item-bottom">
            <p class="item-text">本月新增</p>
            <i class="item-icon el-icon-top"></i>
            <span class="item-number">{{ detailData.certificate || 0 }}</span>
          </div>
          <i class="item-bg">
            <img src="@/assets/images/studioHome/index_statistics_bg.png">
          </i>
        </li>
      </ul>
    </div>


    <div class="manage-box-1 solve">
      <div class="manage-box-title">待审核</div>
      <ul class="solve-list">
        <li class="solve-list-item">
          <div class="item-icon">
            <img src="@/assets/images/studioHome/solve_icon_1.png">
          </div>
          <div class="item-right">
            <p class="item-title">成员加入待审核</p>
            <div class="item-info">
              <p class="info-number">{{ detailData.status_user || 0 }}<span class="number-unit">个</span></p>
              <router-link to="/TearmAudit" class="info-link">去审核></router-link>
            </div>
          </div>
        </li>
        <li class="solve-list-item">
          <div class="item-icon">
            <img src="@/assets/images/studioHome/solve_icon_2.png">
          </div>
          <div class="item-right">
            <p class="item-title">直播管理待审核</p>
            <div class="item-info">
              <p class="info-number">{{ detailData.audit_status_live || 0 }}<span class="number-unit">个</span></p>
              <router-link to="/LiveManage?type=2" class="info-link">去审核></router-link>
            </div>
          </div>
        </li>
        <li class="solve-list-item">
          <div class="item-icon">
            <img src="@/assets/images/studioHome/solve_icon_3.png">
          </div>
          <div class="item-right">
            <p class="item-title">录播管理待审核</p>
            <div class="item-info">
              <p class="info-number">{{ detailData.audit_status_course || 0 }}<span class="number-unit">个</span></p>
              <router-link to="/RecordManage?type=2" class="info-link">去审核></router-link>
            </div>
          </div>
        </li>
        <li class="solve-list-item">
          <div class="item-icon">
            <img src="@/assets/images/studioHome/solve_icon_4.png">
          </div>
          <div class="item-right">
            <p class="item-title">教研活动待审核</p>
            <div class="item-info">
              <p class="info-number">{{ detailData.audit_status_activity || 0 }}<span class="number-unit">个</span></p>
              <router-link to="/ActivityManage?type=2" class="info-link">去审核></router-link>
            </div>
          </div>
        </li>
        <li class="solve-list-item">
          <div class="item-icon">
            <img src="@/assets/images/studioHome/solve_icon_5.png">
          </div>
          <div class="item-right">
            <p class="item-title">研修资源待审核</p>
            <div class="item-info">
              <p class="info-number">{{ detailData.audit_status_resource || 0 }}<span class="number-unit">个</span></p>
              <router-link to="/ResourceList?type=2" class="info-link">去审核></router-link>
            </div>
          </div>
        </li>
        <li class="solve-list-item">
          <div class="item-icon">
            <img src="@/assets/images/studioHome/solve_icon_6.png">
          </div>
          <div class="item-right">
            <p class="item-title">教师文章待审核</p>
            <div class="item-info">
              <p class="info-number">{{ detailData.status_article || 0 }}<span class="number-unit">个</span></p>
              <router-link to="/ArticleList?type=2" class="info-link">去审核></router-link>
            </div>
          </div>
        </li>
        <li class="solve-list-item">
          <div class="item-icon">
            <img src="@/assets/images/studioHome/solve_icon_7.png">
          </div>
          <div class="item-right">
            <p class="item-title">活动报道待审核</p>
            <div class="item-info">
              <p class="info-number">{{ detailData.status_report || 0 }}<span class="number-unit">个</span></p>
              <router-link to="/ReportList?type=2" class="info-link">去审核></router-link>
            </div>
          </div>
        </li>
        <li class="solve-list-item">
          <div class="item-icon">
            <img src="@/assets/images/studioHome/solve_icon_8.png">
          </div>
          <div class="item-right">
            <p class="item-title">成果展示待审核</p>
            <div class="item-info">
              <p class="info-number">{{ detailData.status_certificate1 || 0 }}<span class="number-unit">个</span></p>
              <router-link to="/ResearchAchievement?type=2" class="info-link">去审核></router-link>
            </div>
          </div>
        </li>
        <li class="solve-list-item">
          <div class="item-icon">
            <img src="@/assets/images/studioHome/solve_icon_8.png">
          </div>
          <div class="item-right">
            <p class="item-title">课题展示待审核</p>
            <div class="item-info">
              <p class="info-number">{{ detailData.status_certificate2 || 0 }}<span class="number-unit">个</span></p>
              <router-link to="/ResearchTopic?type=2" class="info-link">去审核></router-link>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div class="head-border"></div>
  </div>
</template>

<script>
export default {
  // name: "",
  data() {
    return {
      detailData: {
        TeachingStudioinfo: {
          photo_url: ""
        }
      },
      unserInfo: JSON.parse(localStorage.getItem("unserInfoData"))
    }
  },
  computed: {},

  components: {},

  methods: {
    init() {
      this.getAdminData();
    },
    //获取工作室详情
    getAdminData() {
      this.$axios.get("index/TeachingStudio/idnexTeachingStudio", {
        params: {
          teaching_studio_id: localStorage.getItem("studioId"),
        },
      }).then((res) => {
        this.detailData = res.data.data;
      });
    },
    //跳转详情
    handlePage(num) {
      switch (Number(num)) {
        case 1:
          this.$router.push("/TearmAudit");
          break;
        case 2:
          this.$router.push("/LiveManage");
          break;
        case 3:
          this.$router.push("/RecordManage");
          break;
        case 4:
          this.$router.push("/ActivityManage");
          break;
        case 5:
          this.$router.push("/ResourceList");
          break;
        case 6:
          this.$router.push("/ArticleList");
          break;
        case 7:
          this.$router.push("/ReportList");
          break;
        case 8:
          this.$router.push("/HonorManage");
          break;
        default:
          break;
      }
    }
  },

  destroyed() {
  },

  mounted() {
  },

  created() {
    let rouName = this.$route.name
    this.$emit('rouname', rouName)
    this.init();
  },
}
</script>

<style lang="scss" scoped>
.manage-index-body {
  background: url('../../assets/images/studioHome/index_bg.png') no-repeat;
  background-size: 100%;
  padding: 0 37px;
  position: relative;

  .manage-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 48px 0 26px 0;
    position: relative;
    z-index: 1;

    .manage-info-left {
      flex: 1;
      overflow: hidden;

      .info-user {
        display: flex;
        align-items: center;

        .user-cover {
          width: 80px;
          height: 80px;
          border-radius: 8px;
          overflow: hidden;
          border: 2px solid #ffffff;

          img {
            width: 100%;
            height: 100%;
            -webkit-user-drag: none;
          }
        }

        .user-right {
          margin-left: 16px;
          flex: 1;
          overflow: hidden;

          .user-studioName {
            font-size: 22px;
            font-weight: bold;
            color: #333333;
            margin-bottom: 8px;
          }

          .user-info {
            display: flex;
            align-items: center;

            .user-name {
              font-size: 16px;
              color: #333333;
            }

            .user-role {
              max-width: 170px;
              border-radius: 4px;
              background: #ff8029;
              padding: 1px 5px;
              display: flex;
              margin-left: 8px;

              span {
                font-size: 14px;
                color: #ffffff;
                white-space: normal;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
              }
            }
          }
        }
      }

    }

    .manage-info-right {
      display: flex;
      margin-left: 50px;

      .census-block {
        display: flex;
        align-items: center;

        .census-icon {
          width: 40px;
          height: 40px;
          border-radius: 100%;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            -webkit-user-drag: none;
          }
        }

        .census-info {
          margin-left: 10px;

          .census-label {
            font-size: 14px;
            font-weight: bold;
            color: #333333;
            line-height: 14px;
            margin-bottom: 2px;
          }

          .census-data {
            display: flex;
            align-items: center;

            .data-text {
              font-size: 26px;
              font-weight: bold;
              color: #3489ff;
              line-height: 26px;
            }

            .data-icon {
              width: 16px;
              margin-left: 4px;
            }
          }
        }
      }
    }
  }

  .manage-box-1 {
    .manage-box-title {
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      margin-bottom: 15px;
    }

    .statistics-list {
      display: flex;
      flex-wrap: wrap;

      .statistics-list-item {
        width: 240px;
        height: 124px;
        border-radius: 8px;
        overflow: hidden;
        position: relative;
        margin: 0 20px 20px 0;

        &:nth-child(4n+4) {
          margin-right: 0;
        }

        .item-bg {
          position: absolute;
          right: 0;
          top: 0;

          img {
            -webkit-user-drag: none;
          }
        }

        .item-top {
          height: 92px;

          .item-title {
            font-size: 16px;
            font-weight: bold;
            color: #333333;
            line-height: 16px;
            padding: 26px 0 12px 26px;
          }

          .item-number {
            font-size: 24px;
            font-weight: bold;
            line-height: 24px;
            padding-left: 26px;
          }
        }

        .item-bottom {
          width: 100%;
          height: 32px;
          padding: 0 26px;
          box-sizing: border-box;
          display: flex;
          align-items: center;

          .item-text {
            font-size: 14px;
            color: #333333;
          }

          .item-icon {
            font-size: 18px;
            color: #EA1111;
            font-weight: bold;
            margin-left: 3px;
          }

          .item-number {
            font-size: 14px;
            font-weight: bold;
            margin-left: 3px;
          }
        }

        &:nth-of-type(1) {
          background: #EBFFFD;

          .item-top {
            .item-number {
              color: #0DAC9E;
            }
          }

          .item-bottom {
            background: #C8F2EE;

            .item-number {
              color: #0DAC9E;
            }
          }
        }

        &:nth-of-type(2) {
          background: #E4FFFD;

          .item-top {
            .item-number {
              color: #17CFC2;
            }
          }

          .item-bottom {
            background: #BEF1ED;

            .item-number {
              color: #1BD2C5;
            }
          }
        }

        &:nth-of-type(3) {
          background: #E4F7FF;

          .item-top {
            .item-number {
              color: #1EB8F7;
            }
          }

          .item-bottom {
            background: #CCEFFF;

            .item-number {
              color: #1EB8F7;
            }
          }
        }

        &:nth-of-type(4) {
          background: #EBFFFD;

          .item-top {
            .item-number {
              color: #0DAC9E;
            }
          }

          .item-bottom {
            background: #C8F2EE;

            .item-number {
              color: #0DAC9E;
            }
          }
        }

        &:nth-of-type(5) {
          background: #F1F4FF;

          .item-top {
            .item-number {
              color: #486DEB;
            }
          }

          .item-bottom {
            background: #E4E9FF;

            .item-number {
              color: #486DEB;
            }
          }
        }

        &:nth-of-type(6) {
          background: #F7EFFF;

          .item-top {
            .item-number {
              color: #9F6ADB;
            }
          }

          .item-bottom {
            background: #EFE0FF;

            .item-number {
              color: #9F6ADB;
            }
          }
        }

        &:nth-of-type(7) {
          background: #FCF0FF;

          .item-top {
            .item-number {
              color: #C765DF;
            }
          }

          .item-bottom {
            background: #F6E3FF;

            .item-number {
              color: #C765DF;
            }
          }
        }
      }
    }
  }

  .solve {
    .manage-box-title {
      font-size: 18px;
      font-weight: bold;
      color: #333333;
    }

    .solve-list {
      display: flex;
      flex-wrap: wrap;

      .solve-list-item {
        width: 239px;
        height: 96px;
        border-radius: 8px;
        padding: 0 22px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        margin: 0 20px 20px 0;

        &:nth-child(4n+4) {
          margin-right: 0;
        }

        .item-icon {
          width: 48px;
          height: 48px;

          img {
            width: 100%;
            height: 100%;
            -webkit-user-drag: none;
          }
        }

        .item-right {
          margin-left: 15px;

          .item-title {
            font-size: 16px;
            font-weight: bold;
            color: #ffffff;
          }

          .item-info {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            .info-number {
              font-size: 24px;
              font-weight: bold;
              color: #ffffff;

              .number-unit {
                font-size: 14px;
                color: #ffffff;
                margin-left: 2px;
              }
            }

            .info-link {
              font-size: 14px;
              color: #ffffff;
            }
          }
        }

        &:nth-of-type(1) {
          background: linear-gradient(-45deg, rgba(0, 209, 193, 1) 0%, rgba(12, 225, 208, 1) 100%);
        }

        &:nth-of-type(2) {
          background: linear-gradient(-45deg, rgba(67, 202, 163, 1) 0%, rgba(74, 212, 172, 1) 100%);
        }

        &:nth-of-type(3) {
          background: linear-gradient(-45deg, rgba(14, 205, 191, 1) 0%, rgba(27, 210, 197, 1) 100%);
        }

        &:nth-of-type(4) {
          background: linear-gradient(-45deg, rgba(7, 168, 234, 1) 0%, rgba(30, 184, 247, 1) 100%);
        }

        &:nth-of-type(5) {
          background: linear-gradient(-45deg, rgba(238, 171, 55, 1) 0%, rgba(244, 183, 77, 1) 100%);
        }

        &:nth-of-type(6) {
          background: linear-gradient(-45deg, rgba(72, 109, 235, 1) 0%, rgba(94, 128, 245, 1) 100%);
        }

        &:nth-of-type(7) {
          background: linear-gradient(-45deg, rgba(159, 106, 219, 1) 0%, rgba(172, 120, 231, 1) 100%);
        }

        &:nth-of-type(8) {
          background: linear-gradient(-45deg, rgba(199, 101, 223, 1) 0%, rgba(215, 128, 236, 1) 100%);
        }

        &:nth-of-type(9) {
          background: linear-gradient(-45deg, rgba(199, 101, 223, 1) 0%, rgba(215, 128, 236, 1) 100%);
        }
      }
    }
  }

  .head-border {
    width: 100%;
    height: 120px;
    border: 1px solid #fff;
    border-width: 1px 1px 0 1px;
    border-radius: 8px;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>